import { required, decimal } from 'vuelidate/lib/validators'

export default {
  validations: {
    params: {
      $each: {
        model: { required, decimal }
      }
    }
  }
}
