<template>
  <div>
    <div class="box box--white mt-32">
      <h3 class="headline--md text-bold">Staking Setting</h3>

      <template v-if="params.length">
        <div v-for="(info, index) in $v.params.$each.$iter" :key="info.id" class="d-flex align-end mt-24 mb-24">
          <p class="text-bold" v-if="info.$model.key === 1">Current Penalty Fee: {{ info.$model.value / 100 }}%</p>

          <div v-if="$auth('Page', currentUser, $accessRule.StakingSettingUpdate).canView()" class="d-flex align-end">
            <div class="form-group mr-8">
              <label class="form-label d-block">New Value</label>
              <input v-model="info.model.$model" type="text" class="form-control form-control--md" />
            </div>

            <button :disabled="info.model.$anyError" class="btn btn--md btn--primary" @click="confirmUpdate(index)">
              Submit
            </button>
          </div>
        </div>
      </template>
    </div>

    <confirm-dialog
      v-if="isModalStakingSettingUpdateOpen"
      :open.sync="isModalStakingSettingUpdateOpen"
      confirmText="Are you sure you would like to update?"
      confirmButtonText="Submit"
      confirmButtonClass="text-purple"
      @confirm="updateSettingInfo"
    />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
import { deepCopy } from '@/utils/json-parser'
import { STAKING_SETTINGS } from '@/utils/key-mapping'
import stakingSettingFormValidator from '@/validations/staking-setting-form'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  data() {
    return {
      isModalStakingSettingUpdateOpen: false,
      settingInfoSelected: {},
      params: []
    }
  },

  mixins: [toggleLoading, stakingSettingFormValidator],

  components: {
    ConfirmDialog
  },

  computed: {
    ...mapState('staking', ['stakingSettings'])
  },

  methods: {
    ...mapActions('staking', ['getStakingSettings', 'updateStakingSetting']),

    initParams() {
      if (!this.stakingSettings || !this.stakingSettings.length) return

      Object.keys(STAKING_SETTINGS).forEach((key, index) => {
        const settingInfo = this.stakingSettings.filter(info => info.key === STAKING_SETTINGS[key])[0]
        if (settingInfo)
          this.params[index] = deepCopy({
            ...settingInfo,
            model: ''
          })
      })
    },

    confirmUpdate(index) {
      if (!this.params[index].model) return

      this.settingInfoSelected = this.params[index]
      this.isModalStakingSettingUpdateOpen = true
    },

    updateSettingInfo() {
      this.toggleLoading()

      const req = {
        ...deepCopy(this.settingInfoSelected),
        value: (parseInt(this.settingInfoSelected.model) * 100).toString()
      }
      delete req.model

      this.updateStakingSetting(req)
        .then(() => {
          this.settingInfoSelected.value = req.value
          this.settingInfoSelected.model = ''
          this.$v.$touch()
        })
        .finally(() => {
          this.toggleLoading()
          this.isModalStakingSettingUpdateOpen = false
        })
    }
  },

  async created() {
    await this.getStakingSettings()
    this.toggleLoading()
    this.initParams()
    this.$v.$touch()
  }
}
</script>
